import { Moment } from 'moment';
import { ParkingGateType, SubSystemType } from '@/model/enum';
import { QueryControl, QueryControlType } from '@/model/query-control';
import { assignDefaultExtend } from '@/service/report';
import ParkingIndexService from '../service/parking-index';
import { QueryModel } from '@/model/query-model';
import { percentFormat } from '@/filter';
import ParkingFacilityService from '../service/parking-facility';
import { download } from '@/service/request';

export class ParkingTrafficFlowRankingQueryModel extends QueryModel {
    constructor(limit?: number) {
        super();
        this.limit = limit;
    }
    @QueryControl({
        label: '区域选择',
        type: QueryControlType.TREE_SELECT,
        optionsPromise: ParkingFacilityService.getLocationTree,
        optionsPromiseParam: SubSystemType.PARKING,
        mode: 'multiple',
        selectChildren: true,
        index: 10,
        span: 8,
        defaultValue: 'ALL',
        required: true
    })
    locationIds: Array<string> = undefined;

    @QueryControl({
        label: '日期范围',
        type: QueryControlType.SELECT_DATE,
        showTime: false,
        span: 10,
        required: true
    })
    dateRange: Array<Moment> = JTL.CONSTANT.TODAY();

    limit: number = undefined;
    type: ParkingGateType = ParkingGateType.EXIT;
    chartExtra: Array<any> = [
        {
            key: 'type',
            type: 'radioGroup',
            options: [ { name: '入口', value: ParkingGateType.ENTRANCE }, { name: '出口', value: ParkingGateType.EXIT } ],
            onchange: (value, vm) => {
                vm.chartSearchClick();
            }
        }
    ];

    toService() {
        return {
            locationIds: this.locationIds,
            startTime: this.dateRange[0].toDate().getTime(),
            endTime: this.dateRange[1].clone().add(1, 'day').startOf('day').toDate().getTime(),
            limit: this.limit,
            type: this.type
        };
    }
}

export async function ParkingTrafficFlowRankingService(model: ParkingTrafficFlowRankingQueryModel) {
    const trafficFlowRankData = await ParkingIndexService.getParkingGateRank(model);
    const chartOptions = {
        title: '出入口流量排名',
        data: trafficFlowRankData.data,
        height: trafficFlowRankData.height,
        settings: trafficFlowRankData.settings,
        legendVisible: false,
        xAxis: trafficFlowRankData.xAxis,
        yAxis: trafficFlowRankData.yAxis,
        extend: trafficFlowRankData.extend,
        tableColumns: [
            {
                title: '名称',
                dataIndex: 'name'
            },
            {
                title: '流量',
                dataIndex: 'value'
            },
            {
                title: '占比',
                dataIndex: 'rate',
                customRender: (text, record, index) => {
                    return percentFormat(text);
                }
            }
        ]
    };
    return assignDefaultExtend(chartOptions);
}

export async function ParkingTrafficFlowRankingExportService(queryModel: ParkingTrafficFlowRankingQueryModel) {
    const url = `${BIBIZ_BASE_REQUEST_PATH}/parkingSystem/parkingGate/hts/rank/export`;
    const res = await download(url, queryModel?.toService(), 'post');
    return res;
}
